import debounce from "lodash.debounce";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import TagManager from "react-gtm-module";
import { connect, useSelector } from "react-redux";
import BaseMD from "../../../google-analytics/events/baseMD";
import {
  updateInput,
  updateInputStatus,
  validationsCompletedContact,
  verifyValidationsCommercialInformation,
  verifyValidationsCompanyData,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import { banks } from "../../sections/CompanyData/ctl_bank";
import styles from "./TextInput.module.sass";
import inputValidations from "./inputUtils/inputValidations";

/**
 * @description
 *  Input utilizado en todo el formulario el campo {canCopy}
 */
function TextInput({
  title,
  type,
  storeName,
  placeholder,
  required,
  inputValidation,
  aditionalInfo,
  callValidation,
  dispatch,
  state,
  thisValueWillBeSetFromRedux,
  hasLengthLimit,
  customRequiredMessage,
  dontValidate,
  canCopy = false,
  copyFrom,
  sectionCopyFrom,
}) {
  const s = styles;
  const [visibilityLabel, setVisibilityLabel] = useState(s.hidden);
  const [validationErrorMsg, setValidationErrorMsg] = useState("");
  const [validationErrorClass, setValidationErrorClass] = useState("");
  const [requiredIdentifier, setRequiredIdentifier] = useState("");
  const currentSectionMD = useSelector((state) => state.currentSection.name);
  const typeOfPersonId = useSelector((state) => state.typeOfPerson?.id);
  const officialData = useSelector((state) => state.officialData);
  function removeValidated() {
    const payload = { name: storeName, validated: false };
    dispatch(updateInputStatus(payload));
  }
  // validations
  function validateRequired() {
    const { value } = document.getElementById(storeName);
    if (value !== "") {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      let payload = {};
      if (dontValidate) {
        payload = { name: storeName, validated: false };
      } else {
        payload = { name: storeName, validated: true };
      }
      dispatch(updateInputStatus(payload));
    } else {
      if (customRequiredMessage) {
        setValidationErrorMsg(customRequiredMessage);
      } else {
        const validationText = inputValidations(inputValidation);
        setValidationErrorMsg(validationText);
      }

      // Error class hides aditional info text
      setValidationErrorClass(s.error);
      removeValidated();
    }
  }

  function validatePhone(input) {
    const { value } = document.getElementById(storeName);
    if (input.toLowerCase().match("^[0-9]{10}$")) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      const validationText = inputValidations(inputValidation);
      setValidationErrorMsg(validationText);
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateEmail(input) {
    const { value } = document.getElementById(storeName);
    if (
      input
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg(
        "Revisa si el formato del correo es correcto (ejemplo: usuario@gmail.com)"
      );
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateCLABE(input) {
    const { value } = document.getElementById(storeName);
    if (input.toLowerCase().match(/\d{18}/)) {
      let bank = banks.find((bank) => bank.acountId === input.slice(0, 3));
      if (bank) {
        setValidationErrorMsg("");
        setValidationErrorClass("");
        const payload = { name: storeName, value, validated: true };
        dispatch(updateInputStatus(payload));
      } else {
        setValidationErrorMsg("Revisa que la CLABE sea correcta");
        setValidationErrorClass(s.error);
        const payload = { name: storeName, value, validated: false };
        dispatch(updateInputStatus(payload));
      }
    } else {
      setValidationErrorMsg("Revisa que la CLABE tenga 18 dígitos");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateCURP() {
    let { value } = document.getElementById(storeName);
    value = value.toUpperCase();

    // update value to uppercase before the validation
    dispatch(updateInput({ name: storeName, value, validated: false }));

    if (
      value.match(
        /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/
      )
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg("Revisa que el CURP tenga 18 letras y números");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateRFC() {
    let { value } = document.getElementById(storeName);
    value = value.toUpperCase();

    // update value to uppercase before the validation
    dispatch(updateInput({ name: storeName, value, validated: false }));

    if (
      value.match(
        /^([A-ZÑ]|&){3,4}\d{2}(0[1-9]|1[0-2])([12]\d|0[1-9]|3[01])[A-Z0-9]{3}$/
      )
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg("Escribe las 13 letras y números del RFC");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateName(inputName) {
    const { value } = document.getElementById(storeName);
    if (inputName.match(/^[a-zA-ZÀ-ÿ\s]{1,250}$/)) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };

      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg("Escribe el nombre o nombres");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateDate(input) {
    const { value } = document.getElementById(storeName);
    if (
      input.match(/^(0?[1-9]|[12][0-9]|3[01])[/-](0?[1-9]|1[012])[/-]\d{4}$/)
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg("Escribe el día, el mes y el año (DD/MM/AAAA)");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
      // error class hides aditional info text
    }
  }

  function validateURL(input) {
    const regEx =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    const { value } = document.getElementById(storeName);
    if (!value) {
      setValidationErrorMsg("Escribe el sitio web");
    } else if (
      input
        .toLowerCase()
        .match(regEx)
    ) {
      setValidationErrorMsg("");
      setValidationErrorClass("");
      const payload = { name: storeName, value, validated: true };
      dispatch(updateInputStatus(payload));
    } else {
      setValidationErrorMsg("Revisa que el sitio web sea correcto");
      setValidationErrorClass(s.error);
      const payload = { name: storeName, value, validated: false };
      dispatch(updateInputStatus(payload));
    }
  }

  function handleOnBlur() {
    const { value } = document.getElementById(storeName);

    if (required) {
      validateRequired();
    }

    if (inputValidation) {
      switch (inputValidation) {
        case "phone":
          validatePhone(value);
          break;
        case "email":
          validateEmail(value);
          break;
        case "name":
          validateName(value);
          break;
        case "rfc":
          validateRFC();
          break;
        case "CLABE":
          validateCLABE(value);
          break;
        case "curp":
          validateCURP();
          break;
        case "dd/mm/aaaa":
          validateDate(value);
          break;
        case "url":
          validateURL(value);
          break;

        default:
          break;
      }

      if (!required) {
        removeValidated();
      }
    }

    const currentSection = state.currentSection.name;
    switch (currentSection) {
      case "contact":
        dispatch(validationsCompletedContact());
        break;
      case "officialData":
        dispatch(verifyValidationsOfficialData());
        break;
      case "companyData":
        dispatch(verifyValidationsCompanyData());
        break;
      case "commercialInfo":
        dispatch(verifyValidationsCommercialInformation());
        break;
      default:
        break;
    }
  }
  const debouncedHandleOnBlur = React.useMemo(
    () => debounce(handleOnBlur, 700),
    []
  );
  let inputValue;

  const valueFromStore = (reduxState, sName) => {
    let currentSection = reduxState.currentSection.name;
    if (canCopy) currentSection = sectionCopyFrom;
    if (reduxState.currentSection.name) {
      reduxState[currentSection].map((input) => {
        if (input.name === sName) {
          inputValue = input.value;
        }
        return inputValue;
      });
    }
    return inputValue;
  };

  function returnFalseOnLargeValues(maxLength, valueFromInput) {
    return valueFromInput.length <= maxLength;
  }

  function handleOnChangeInputValue(ivalue) {
    const { value } = document.getElementById(storeName);
    setValidationErrorClass("");
    
    if (
      hasLengthLimit !== false &&
      !returnFalseOnLargeValues(hasLengthLimit, value)
    ) {
      return false;
    }

    if (value !== "") {
      setVisibilityLabel(s.visible);
    } else {
      setVisibilityLabel(s.hidden);
    }

    const pay = { name: storeName, value: ivalue };
    dispatch(updateInput(pay));
    debouncedHandleOnBlur();
    return true;
  }

  function validateValueOnRender() {
    const { value } = document.getElementById(storeName) ?? { value: "" };
    if (value !== "") {
      setVisibilityLabel(s.visible);
    } else {
      setVisibilityLabel(s.hidden);
    }
  }

  // use when value is set from redux state

  function timerThatShowsLabel() {
    setTimeout(() => {
      validateValueOnRender();
    }, 200);
  }
  if (thisValueWillBeSetFromRedux) {
    timerThatShowsLabel();
  }
  useEffect(() => {
    validateValueOnRender();
    if (required) {
      setRequiredIdentifier("*");
      if (callValidation) {
        validateRequired();
      }
    }
  }, [callValidation]);
  useEffect(() => {
    if (validationErrorMsg !== "" && !canCopy) {
      TagManager.dataLayer(
        BaseMD.MD3C(
          validationErrorMsg,
          currentSectionMD,
          title,
          officialData,
          typeOfPersonId
        )
      );
    }
  }, [validationErrorMsg]);
  useEffect(() => {
    if (canCopy) {
      setVisibilityLabel(s.visible);
    }
  }, [canCopy]);
  // Conditional attribute

  function dontAllowNumbers(event) {
    // Allow: Numbers
    if (!/\d/.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <div className={`${canCopy ? "" : validationErrorClass} ${s.input}`}>
      <label
        htmlFor={storeName}
        className={`${visibilityLabel} ${s.inputTitle}`}
      >
        {title + requiredIdentifier}
      </label>
      <input
        className={s.inputText}
        disabled={canCopy}
        value={valueFromStore(state, canCopy ? copyFrom : storeName) || ""}
        type={type}
        id={storeName}
        onKeyPress={type === "number" ? dontAllowNumbers : null}
        autoComplete="off"
        placeholder={placeholder + requiredIdentifier}
        onChange={(e) => {
          handleOnChangeInputValue(e.target.value);
        }}
        onBlur={handleOnBlur}
        maxLength={250}
      />
      {!canCopy && (
        <small className={s.inputErrorFeedback}>{validationErrorMsg}</small>
      )}
      <small className={s.inputAditionalInfo}>{aditionalInfo}</small>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    state,
  };
}

export default connect(mapStateToProps)(TextInput);

TextInput.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  dontValidate: PropTypes.bool,
  placeholder: PropTypes.string.isRequired,
  required: PropTypes.bool,
  inputValidation: PropTypes.string,
  aditionalInfo: PropTypes.string,
  storeName: PropTypes.string,
  callValidation: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  dispatch: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
  thisValueWillBeSetFromRedux: PropTypes.bool,
  hasLengthLimit: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  customRequiredMessage: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
};

TextInput.defaultProps = {
  dontValidate: false,
  type: "search",
  callValidation: false,
  required: false,
  inputValidation: "",
  aditionalInfo: "",
  storeName: "",
  thisValueWillBeSetFromRedux: false,
  hasLengthLimit: false,
  customRequiredMessage: false,
};
