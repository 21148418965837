import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  updateInputExtra,
  verifyValidationsOfficialData,
} from "../../../redux-modules/form/actions";
import TextInputBase from "../TextInputBase/TextInputBase";

import styles from "./TextInputWithDobleValue.module.sass";

/**
 * This Component is required to to have rfc and fullName inputs in one object in redux store
 * this is the only way to have a single validation for both inputs
 *
 * @param {string} callValidation - Call validation of section
 * @param {string} index - this component is used in a map, this is the index of the map
 * @param {string} onClickDelete - function to delete the current associate or admin inputs
 * @returns {JSX.Element}
 */
export default function TextInputWithDobleValue({
  typeOfInput,
  callValidation,
  index,
  indexRemove = 0,
  onClickDelete,
}) {
  const s = styles;
  const dispatch = useDispatch();

  let inputStateName;

  if (typeOfInput === "associates") {
    inputStateName = `extraAssociate${index}`;
  } else if (typeOfInput === "administrators") {
    inputStateName = `extraAdministrator${index}`;
  }
  // save the name in store
  function handleOnChangeName(e) {
    const pay = { name: inputStateName, fullName: e };
    dispatch(updateInputExtra(pay));
  }

  // save the RFC in store
  function handleOnChangeRfc(e) {
    const pay = { name: inputStateName, rfc: e };
    dispatch(updateInputExtra(pay));
  }

  // current validations for both inputs
  const [extraInputsValidations, setExtraInputsValidations] = useState({
    rfc: false,
    fullName: false,
  });

  // call this when a value in the inputs change
  function setValidationValues(validationResult) {
    if (validationResult.rfc !== undefined) {
      setExtraInputsValidations((prev) => ({
        ...prev,
        rfc: validationResult.rfc,
      }));
    }

    if (validationResult.fullName !== undefined) {
      setExtraInputsValidations((prev) => ({
        ...prev,
        fullName: validationResult.fullName,
      }));
    }
  }

  // when value change we set the validation results so this run and update the validation status of the input in the store
  useEffect(() => {
    let validated = false;
    if (extraInputsValidations.rfc && extraInputsValidations.fullName) {
      validated = true;
    }
    const pay = {
      name: inputStateName,
      validated,
    };
    dispatch(updateInputExtra(pay));
    dispatch(verifyValidationsOfficialData());
  }, [extraInputsValidations]);

  return (
    <div className={s.newMember}>
      <TextInputBase
        required
        handleOnChange={(e) => {
          handleOnChangeName(e);
        }}
        title="Nombre y apellidos"
        placeholder="Nombre y apellidos"
        storeName={inputStateName}
        callValidation={callValidation}
        inputValidation="fullName"
        setValidationValues={(values) => {
          setValidationValues(values);
        }}
        fullNameInput
      />

      <TextInputBase
        required
        handleOnChange={(e) => {
          handleOnChangeRfc(e);
        }}
        title="RFC"
        placeholder="RFC"
        storeName={inputStateName}
        inputValidation="rfc"
        callValidation={callValidation}
        setValidationValues={(values) => {
          setValidationValues(values);
        }}
        rfcInput
      />
      {index > 0 + indexRemove && (
        <button
          className={s.deleteMember}
          type="button"
          onClick={onClickDelete}
        >
          X Quitar
        </button>
      )}
    </div>
  );
}

TextInputWithDobleValue.propTypes = {
  typeOfInput: PropTypes.string.isRequired,
  callValidation: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onClickDelete: PropTypes.func.isRequired,
};
