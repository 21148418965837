import convertArrayToJson from "../utils/js/convertArrayToJson";
import { Associate } from "./entities/Associate";
import makeCtrRecepcionPopData from "./entities/CtrRecepcionPopData";
import Franchise from "./entities/Franchise";
import { generateKeys } from "./generate";
import getDataFromRedux from "./getDataFromRedux";
import postAssociate from "./postAssociate";
import {
  returnOnlyExtraAdmins,
  returnOnlyExtraAssociates,
} from "./returnExtraAssociatesAndAdmins";
import searchInputByNameAndSection from "./searchInputByNameAndSection";

export default async function makeBodyPostAssociate(data) {
  // Form data from each step of the form
  const logo = searchInputByNameAndSection("logo", "documents", data);
  const contact = convertArrayToJson(data.contact);
  const officialData = convertArrayToJson(data.officialData);
  const companyData = convertArrayToJson(data.companyData);
  const commercialInfo = convertArrayToJson(data.commercialInfo);
  const typeOfPersonId = data.typeOfPerson.id;
  const typeOfPersonName = data.typeOfPerson.name;

  const associateIntegrations = [];
  let phoneAsociado = "";
  let typeOfCompany = 612;

  const ctrContactAdmin = {
    name: contact.contactAdminName,
    email: contact.contactAdminEmail,
    phone: contact.contactAdminPhone,
    surname: contact.contactAdminFirstLastname,
    second_surname: contact.contactAdminSecondLastname || "",
    email_type: contact.contactAdminEmailType,
    phone_type: contact.contactAdminPhoneType,
    type_contact: "Administrador",
    rfc: contact.contactAdminRFC,
    contact_role: "Administrador",
  };

  const ctrContactLegal = {
    name: officialData.moralTaxpayerName || officialData.physicalTaxpayerName,
    email: contact.contactAdminEmail,
    phone: contact.contactAdminPhone,
    surname:
      officialData.moralTaxpayerLastname ||
      officialData.physicalTaxpayerLastname,
    second_surname:
      officialData.moralTaxpayerMothersLastname ||
      officialData.physicalTaxpayerMothersLastname ||
      "",
    email_type: contact.contactAdminEmailType,
    phone_type: contact.contactAdminPhoneType,
    type_contact: "Legal", // not captured in the form
    rfc: officialData.moralTaxpayerRfc || officialData.physicalTaxpayerRfc,
    contact_role: "Legal", // not captured in the form
  };

  const ctrContactTechnical = {
    name: contact.contactTechName,
    email: contact.contactTechEmail,
    phone: contact.contactTechPhone,
    surname: contact.contactTechFirstLastname,
    second_surname: contact.contactTechSecondLastname || "",
    email_type: contact.contactTechEmailType,
    phone_type: contact.contactTechPhoneType,
    type_contact: "Técnico",
    rfc: "",
    contact_role: "Técnico",
  };

  const ctrContactComercial = {
    name: contact.contactComercialName,
    email: contact.contactComercialEmail,
    phone: contact.contactComercialPhone,
    surname: contact.contactComercialFirstLastname,
    second_surname: contact.contactComercialSecondLastname || "",
    email_type: contact.contactComercialEmailType,
    phone_type: contact.contactComercialPhoneType,
    type_contact: "Comercial",
    rfc: "",
    contact_role: "Comercial",
  };

  const ctrPuntoVentaIntegration = {
    minimum_amount: 100,
    maximum_amount: 25000,
    interest_rate: 45,
    payment_deadline: 12,
  };

  // to future use if we need to add more integrations
  // let ctrSistemaAfiliadoIntegration = {
  //     monto_min: 100,
  //     monto_max: 25000,
  //     tasa_interes: 43,
  //     plazo_pago: 12,
  // };

  const ctrOnlineIntegration = {
    minimum_amount: 100,
    maximum_amount: 25000,
    interest_rate: 45,
    payment_deadline: 12,
  };

  let extraData = null;

  function setAssociateIntegrationTypes(comercialData) {
    if (comercialData.commercialInformationWhereToUseCPPhysical === true) {
      associateIntegrations.push({
        wallet_type_id: 1,
        sale_concept: null,
        integration_type: "VentaFisico",
        authorization_method: '{"HUELLA":"0","OTP":"1"}',
        commission: 42,
        iva: 16,
        integration_type_id: 1,
        Integration_deadlines: ctrPuntoVentaIntegration,
      });
    }

    if (comercialData.commercialInformationOnline === true) {
      extraData = {
        iduPlugin: comercialData.commercialInformationPlataforma,
        urlWebSite: comercialData.commercialInformationWebsite,
      };

      const keys = generateKeys();
      associateIntegrations.push({
        wallet_type_id: 1,
        sale_concept: null,
        integration_type: "VentaOnline",
        authorization_method: '{"HUELLA":"0","OTP":"1"}',
        commission: 45,
        iva: 16,
        integration_type_id: 3,
        Integration_deadlines: ctrOnlineIntegration,
        online_Keys: {
          public_key: keys.public,
          private_key: keys.private,
        },
      });
    }
    extraData = {
      ...extraData,
      desFuenteOrigen: comercialData.commercialInformationSource,
    };

    return true;
  }

  function setCodeTypePerson(typeOfPerson) {
    if (typeOfPerson === 2) {
      typeOfCompany = 612;
    } else if (typeOfPerson === 1) {
      typeOfCompany = 601;
    }
    return typeOfCompany;
  }
  setCodeTypePerson(typeOfPersonId);
  setAssociateIntegrationTypes(commercialInfo);

  if (data.typeOfPerson.id === 1) {
    phoneAsociado = companyData.localPhoneCompanyData;
  } else {
    phoneAsociado = contact.contactAdminPhone;
  }

  const ctrRecepcionPopData = makeCtrRecepcionPopData(
    officialData,
    commercialInfo,
    companyData,
    typeOfPersonName
  );
  if (companyData.typeOfPerson === "Persona moral") {
    typeOfCompany = 601;
  } else if (companyData.typeOfPerson === "Persona física") {
    typeOfCompany = 612;
  }

  const metamapData = {
    Files: null,
    documentData: await getDataFromRedux(data),
  };

  const infoToCreateAssociate = {
    Franchise: Franchise(officialData, companyData, commercialInfo),
    Associated: Associate(
      officialData,
      companyData,
      commercialInfo,
      contact,
      phoneAsociado,
      typeOfCompany,
      logo
    ),
    Reception_pop: ctrRecepcionPopData,
    Integration: associateIntegrations,
    Associated_contact: [
      ctrContactAdmin,
      ctrContactLegal,
      ctrContactComercial,
      ctrContactTechnical,
      ...returnOnlyExtraAssociates(data.officialData),
      ...returnOnlyExtraAdmins(data.officialData),
    ],
    MetamapResult: metamapData,
    ExtraData: extraData,
  };

  return postAssociate(infoToCreateAssociate);
}
