import { PropTypes } from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import {
  copyData,
  cleanStore,
  verifyValidationsOfficialData,
} from "../../../../redux-modules/form/actions";
import { DivisionLine } from "../../../DivisionLine";
import { CheckBox } from "../../../inputs/CheckBox";
import { PCInput } from "../../../inputs/PCInput";
import { SelectUI } from "../../../inputs/SelectUI/Select";
import TextInput from "../../../inputs/TextInput/TextInput";
import styles from "./PhysicalTaxpayer.module.sass";
import { SelectWithId } from "../../../inputs/SelectWithId";
import { getRegimen } from "../../../../regimenes/regimenes";
// eslint-disable-next-line react/prop-types
function Component({ callValidation, state, dispatch }) {
  const s = styles;
  const currentSection = state.currentSection.name;
  const [checkTaxpayer, setCheckTaxpayer] = useState(
    state.officialData.find((item) => item.name === "checkDatosTaxpayer")
      ?.value ?? false
  );
    const regimenes = getRegimen("Fisica");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function getValuesFromStore(reduxState) {
    let options;
    reduxState[currentSection].map((inputItem) => {
      if (
        inputItem.name ===
        `suburbOptions${capitalizeFirstLetter(currentSection)}`
      ) {
        options = inputItem.value;
      }
      return options;
    });
    return options;
  }
  const copyToState = () => {
    const payloadTaxpayer = {
      physicalTaxpayerName: "contactAdminName",
      physicalTaxpayerLastname: "contactAdminFirstLastname",
      physicalTaxpayerMothersLastname: "contactAdminSecondLastname",
      physicalTaxpayerRfc: "contactAdminRFC",
    };
    dispatch(copyData(payloadTaxpayer));
  };
  const cleanDataTaxpayer = () => {
    const payload = {
      sectionName: "officialData",
      fields: [
        "physicalTaxpayerName",
        "physicalTaxpayerLastname",
        "physicalTaxpayerMothersLastname",
        "physicalTaxpayerRfc",
      ],
    };
    dispatch(cleanStore(payload));
  };
  return (
    <div className={s.taxpayerData}>
      <div className={s.titleAndDescription}>
        <div className={s.column}>
          <h2 className={s.secondaryTitle}>Datos del contribuyente</h2>
          <CheckBox
            classExt={s.changeCheckbox}
            storeName="checkDatosTaxpayer"
            title="Usar los mismos datos del administrador"
            externalHandler={(e) => {
              setCheckTaxpayer(e.target.checked);
              if (e.target.checked) {
                copyToState();
              } else {
                cleanDataTaxpayer();
              }
              dispatch(verifyValidationsOfficialData());
            }}
            isRequired={false}
          />
        </div>
        <p>Escribe los datos de la persona física con actividad empresarial</p>
      </div>
      <div className={s.inputsTaxpayer}>
        <TextInput
          required
          title="Nombre"
          placeholder="Nombre"
          storeName="physicalTaxpayerName"
          inputValidation="name"
          callValidation={callValidation}
          canCopy={checkTaxpayer}
          copyFrom="contactAdminName"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="Apellido paterno"
          placeholder="Apellido paterno"
          storeName="physicalTaxpayerLastname"
          inputValidation="lastName"
          callValidation={callValidation}
          canCopy={checkTaxpayer}
          copyFrom="contactAdminFirstLastname"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="Apellido materno"
          placeholder="Apellido materno"
          storeName="physicalTaxpayerMothersLastname"
          inputValidation="mothersLastName"
          callValidation={callValidation}
          canCopy={checkTaxpayer}
          copyFrom="contactAdminSecondLastname"
          sectionCopyFrom="contact"
        />

        <TextInput
          required
          title="RFC"
          placeholder="RFC"
          storeName="physicalTaxpayerRfc"
          inputValidation="rfc"
          aditionalInfo="Escribe el RFC con homoclave"
          callValidation={callValidation}
          canCopy={checkTaxpayer}
          copyFrom="contactAdminRFC"
          sectionCopyFrom="contact"
        />

        <div className={s.selectWrapper}>
          <SelectWithId
            id="physicalTaxpayerRegimen"
            storeName="physicalTaxpayerRegimen"
            name="Regimen"
            defaultValue="Regimen*"
            customRequiredMessage="Elige una opción para continuar"
            options={regimenes}
            callValidation={callValidation}
          />
        </div>

      </div>
      <DivisionLine />
      <div className={s.titleAndDescription}>
        <h2 className={s.secondaryTitle}>Dirección fiscal del contribuyente</h2>
        <h3 className={s.sectionDescription}>
          Escribe la dirección fiscal de la persona física con actividad
          empresarial
        </h3>
      </div>
      <div className={s.addressWrapper}>
        <TextInput
          required
          title="Calle"
          placeholder="Calle"
          storeName={`street${capitalizeFirstLetter(currentSection)}`}
          inputValidation="street"
          callValidation={callValidation}
        />

        <TextInput
          required
          title="Número exterior"
          placeholder="Número exterior"
          storeName={`exteriorNumber${capitalizeFirstLetter(currentSection)}`}
          inputValidation="exteriorNumber"
          callValidation={callValidation}
        />

        <TextInput
          title="Número interior"
          placeholder="Número interior"
          storeName={`interiorNumber${capitalizeFirstLetter(currentSection)}`}
          callValidation={callValidation}
          dontValidate
        />

        <PCInput
          required
          title="Código postal"
          placeholder="Código postal"
          storeName={`postalCode${capitalizeFirstLetter(currentSection)}`}
          inputValidation="postalCode"
          callValidation={callValidation}
        />

        <div className={s.whiteSpace} />

        <div className={s.whiteSpace} />

        <SelectUI
          id="slc_colonia"
          name="Colonia"
          storeName={`suburb${capitalizeFirstLetter(currentSection)}`}
          defaultValue="Colonia"
          options={getValuesFromStore(state) || ["Introduce un código postal"]}
          callValidation={callValidation}
        />

        <TextInput
          required
          title="Municipio o alcaldía"
          placeholder="Municipio o alcaldía"
          storeName={`municipality${capitalizeFirstLetter(currentSection)}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <div className={s.whiteSpace} />

        <TextInput
          required
          title="Estado"
          placeholder="Estado"
          storeName={`state${capitalizeFirstLetter(currentSection)}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <TextInput
          required
          title="País"
          placeholder="País"
          storeName={`country${capitalizeFirstLetter(currentSection)}`}
          thisValueWillBeSetFromRedux
          callValidation={callValidation}
          inputValidation="option"
        />

        <div className={s.whiteSpace} />

        <TextInput
          required
          title="Teléfono"
          type="number"
          placeholder="Teléfono"
          storeName={`phoneNumber${capitalizeFirstLetter(currentSection)}`}
          aditionalInfo="Escribe el número a 10 dígitos"
          callValidation={callValidation}
          inputValidation="phone"
          hasLengthLimit={10}
        />
      </div>
    </div>
  );
}

Component.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  state: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return { state };
}

export const PhysicalTaxpayer = connect(mapStateToProps, null)(Component);
