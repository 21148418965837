// src/redux-modules/auth/index.js
// import * as types from "./types";
// 6 confirmation

import {
  ADD_ERROR_MESSAGE_TO_DOCUMENTS,
  CHANGE_TYPE_OF_PERSON,
  CLEAR_DATA,
  CLEAR_POP_ADDRESS,
  NEW_ADMIN_ADDED,
  NEW_ADMIN_REMOVED,
  NEW_ASSOCIATE_ADDED,
  NEW_ASSOCIATE_REMOVED,
  NEXT_SECTION,
  LOG_OUT,
  PREV_SECTION,
  RESET_ADMIN_COUNT,
  UPDATE_INPUT,
  UPDATE_INPUT_METAMAP,
  UPDATE_STEPPER_LOADER,
  UPDATE_INPUT_EXTRA,
  UPDATE_INPUT_STATUS,
  UPDATE_RADIO,
  UPDATE_VALIDATION_ERRORS,
  VALIDATIONS_COMPLETED_CONTACT,
  VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION,
  VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA,
  VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS,
  VERIFY_VALIDATIONS_OFFICIAL_DATA,
  UPDATE_METAMAP_FILEUPLOAD_DATA,
  DELETE_ITEM_BY_NAME,
  UPDATE_INPUT_LOGO,
  COPY_DATA,
  CLEAN_STORE,
  LIST_FILE_ERROR,
  REPLACE_STATE,
  SAVE_STATE_ON_SERVER,
} from "./types";

import saveState from "../../scripts/saveState";

// defaults export reducer
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const initialState = {
  currentSection: { value: 0, name: "welcome" },
  requiredInputsCount: {
    officialData: null,
    counterNewAssociates: 0,
    counterNewAdmins: 0,
  },
  welcome: [],
  contact: [],
  officialData: [],
  companyData: [],
  commercialInfo: [],
  documents: [],
  infoMetamap: [],
};
// reducer
// eslint-disable-next-line default-param-last
// @supressWarnings("all")
export const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case CLEAR_DATA:
      state = {
        ...state,
        currentSection: {
          value: 0,
          name: "welcome",
        },
        officialData: [{}],
        companyData: [{}],
        commercialInfo: [{}],
      };
      break;

    case SAVE_STATE_ON_SERVER: {
      saveState(state);
      return {
        ...state,
      };
    }
    case VERIFY_VALIDATIONS_COMPLETED_DOCUMENTS: {
      const totalValidationsDocuments = 1;
      let currentValidations = 0;

      const formSection = state.currentSection.name;
      const inputsArray = [...state[formSection]];

      inputsArray.map((input) => {
        if (input.validated === true) {
          currentValidations += 1;
        }
        return currentValidations;
      });

      if (currentValidations >= totalValidationsDocuments) {
        return { ...state, totalValidationsDocuments: true };
      }
      return { ...state, totalValidationsDocuments: false };
    }

    case VERIFY_VALIDATIONS_COMMERCIAL_INFORMATION: {
      let totalValidationsCommercialInformation = 4;
      let currentValidations = 0;
      const formSection = state.currentSection.name;
      const inputsArray = [...state[formSection]];

      inputsArray.map((input) => {
        if (
          input.name === "commercialInformationWhereToUseCPPhysical" &&
          input.value === true
        ) {
          totalValidationsCommercialInformation += 9;
        }
        if (
          input.name === "commercialInformationOptionsPOPAddress" &&
          input.value === "2"
        ) {
          totalValidationsCommercialInformation += 7;
        }

        if (
          input.name === "commercialInformationOnline" &&
          input.value === true
        ) {
          totalValidationsCommercialInformation += 2;
        }

        if (input.validated === true) {
          currentValidations += 1;
        }
        return input;
      });

      if (currentValidations >= totalValidationsCommercialInformation) {
        return { ...state, commercialInformationValidations: true };
      }
      return { ...state, commercialInformationValidations: false };
    }

    case VERIFY_VALIDATIONS_COMPLETED_COMPANY_DATA: {
      let totalValidationsCompanyData = 0;

      if (state.typeOfPerson?.id === 1) {
        totalValidationsCompanyData = 17;
      } else {
        totalValidationsCompanyData = 6;
      }

      let currentValidations = 0;
      const formSection = state.currentSection.name;
      const inputsArray = [...state[formSection]];

      inputsArray.map((input) => {
        if (input.validated === true) {
          currentValidations += 1;
        }
        return input;
      });

      if (currentValidations >= totalValidationsCompanyData) {
        return { ...state, companyDataValidations: true };
      }
      return { ...state, companyDataValidations: false };
    }

    case VALIDATIONS_COMPLETED_CONTACT: {
      const totalValidationsContactSection = 22;
      let currentValidations = 0;
      const formSection = state.currentSection.name;
      const inputsArray = [...state[formSection]];
      // eslint-disable-next-line no-restricted-syntax
      for (const element of inputsArray) {
        if (element.validated) {
          currentValidations += 1;
        }
      }
      if (currentValidations >= totalValidationsContactSection) {
        return { ...state, contactValidations: true };
      }
      return { ...state, contactValidations: false };
    }

    case VERIFY_VALIDATIONS_OFFICIAL_DATA: {
      let totalValidationsOfficialData = 0;
      let sectionValidations = 0;
      // Se suma 2 porque es obligatorio al menos 2 socios
      const newAssociatesRequiredInputs =
        2 + state.requiredInputsCount.counterNewAssociates;
      const newAdminsRequiredInputs =
        state.requiredInputsCount.counterNewAdmins;

      const formSection = state.currentSection.name;
      const inputsArray = [...state[formSection]];

      const nonVariableInputsMoral = 8;
      const nonVariableInputsPhysical = 15;

      // typeOfPerson should be defined by default in the state
      // to avoid errors
      if (state.typeOfPerson?.id === 1) {
        totalValidationsOfficialData =
          nonVariableInputsMoral +
          newAssociatesRequiredInputs +
          newAdminsRequiredInputs;
      } else {
        totalValidationsOfficialData = nonVariableInputsPhysical;
      }

      inputsArray.map((input) => {
        if (input.validated === true) {
          sectionValidations += 1;
        }
        return input;
      });

      if (sectionValidations >= totalValidationsOfficialData) {
        return { ...state, officialDataValidations: true };
      }
      return { ...state, officialDataValidations: false };
    }

    case NEW_ASSOCIATE_ADDED: {
      state.requiredInputsCount.counterNewAssociates += 1;
      return {
        ...state,
      };
    }

    case NEW_ASSOCIATE_REMOVED: {
      state.requiredInputsCount.counterNewAssociates -= 1;

      const nameItemToRemove = `extraAssociate${action.payload.index}`;

      const newState = state.officialData.filter(
        (item) => !item.name.includes(nameItemToRemove)
      );

      state.officialData = newState;

      return {
        ...state,
      };
    }

    case REPLACE_STATE: {
      state = action.payload;
      return {
        ...state,
      };
    }

    case NEW_ADMIN_ADDED: {
      state.requiredInputsCount.counterNewAdmins += 1;
      return {
        ...state,
      };
    }

    case NEW_ADMIN_REMOVED: {
      state.requiredInputsCount.counterNewAdmins -= 1;

      const nameItemToRemove = `extraAdministrator${action.payload.index}`;

      const newState = state.officialData.filter(
        (item) => !item.name.includes(nameItemToRemove)
      );

      state.officialData = newState;

      return {
        ...state,
      };
    }

    case RESET_ADMIN_COUNT: {
      state.requiredInputsCount.counterNewAdmins = 0;

      const newState = state.officialData.filter(
        (item) => !item.name.includes("extraAdministrator")
      );

      state.officialData = newState;

      return {
        ...state,
      };
    }

    case UPDATE_INPUT_EXTRA: {
      const formSection = state.currentSection.name;
      let inputAlreadyExists;

      const findInput = state[formSection].find((input) => {
        return input.name === action.payload.name;
      });

      let inputsArray = [...state[formSection]];

      if (findInput !== undefined) {
        inputAlreadyExists = true;
      }

      if (inputAlreadyExists) {
        inputsArray = inputsArray.map((input) => {
          if (input.name === action.payload.name) {
            if (action.payload.rfc !== undefined) {
              input.rfc = action.payload.rfc;
            } else if (action.payload.fullName !== undefined) {
              input.fullName = action.payload.fullName;
            }

            if (action.payload.validated !== undefined) {
              input.validated = action.payload.validated;
            }
          }
          return input;
        });
      } else {
        inputsArray.push(action.payload);
      }

      return {
        ...state,
        [formSection]: inputsArray,
      };
    }

    case UPDATE_INPUT: {
      let inputAlreadyExists;
      const formSection = state.currentSection.name;

      state[formSection].map((input) => {
        if (input.name === action.payload.name) {
          inputAlreadyExists = true;
        }
        return input;
      });

      let inputsArray = [...state[formSection]];

      if (inputAlreadyExists) {
        inputsArray = inputsArray.map((input) => {
          if (input.name === action.payload.name) {
            input.value = action.payload.value;
          }
          return input;
        });
      } else {
        inputsArray.push(action.payload);
      }

      return {
        ...state,
        [formSection]: inputsArray,
      };
    }

    case UPDATE_INPUT_METAMAP: {
      const inputsArray = state.documents;

      /** Data from metamap * */
      const d = action.payload.value;

      let currentInput;
      if (
        // this runs when we set the input value by first time
        // no metamap update here
        action.payload.sendThisWhenSendInfoByFirstTime
      ) {
        currentInput = inputsArray.find(
          (input) => input.name === action.payload.name
        );
      } else {
        currentInput = inputsArray.find(
          (input) =>
            input.flowId === action.payload.flowId &&
            input.verificationId === action.payload.verificationId
        );
      }

      if (currentInput && action.payload.sendThisWhenSendInfoByFirstTime) {
        currentInput.flowId = action.payload.flowId;
        currentInput.verificationId = action.payload.verificationId;
        currentInput.value.file = d.file ? d.file : null;
        currentInput.value.fileName = d.fileName ? d.fileName : null;
      }

      if (currentInput) {
        const flowIdIne = process.env.REACT_APP_FLOWID_INE_FRONT;
        const currentErrors = currentInput.value.errors;

        // when file is provided, we remove null file error
        if (action.payload.value.file) {
          currentInput.value.errors = currentErrors.filter(
            (error) => error !== "El archivo es requerido"
          );
        }

        if (d.errors) {
          currentInput.value.errors = [...currentErrors, ...d.errors];

          // if action.payload.flowId is ineFron or ineBack
          // update both ineFront and ineBack with the same errors

          if (action.payload.flowId === flowIdIne) {
            const ineFrontInput = inputsArray.find(
              (input) => input.name === "ineFront"
            );
            const ineBackInput = inputsArray.find(
              (input) => input.name === "ineBack"
            );

            if (ineFrontInput) {
              ineFrontInput.value.errors = [...currentErrors, ...d.errors];
            }
            if (ineBackInput) {
              ineBackInput.value.errors = [...currentErrors, ...d.errors];
            }
          }
        } else if (d.errors === null) {
          currentInput.value.errors = [];
        }

        if (d.status) {
          currentInput.value.status = d.status;

          if (action.payload.flowId === flowIdIne) {
            const ineFrontInput = inputsArray.find(
              (input) => input.name === "ineFront"
            );
            const ineBackInput = inputsArray.find(
              (input) => input.name === "ineBack"
            );

            if (ineFrontInput) {
              ineFrontInput.value.status = d.status;
            }
            if (ineBackInput) {
              ineBackInput.value.status = d.status;
            }
          }
        }

        if (d.data) {
          // check data for: documentNumber, OCRNumber, subtype
          // if they are present save in value.sendToEndpoint

          const current = currentInput.value.data;

          // data isn't an array from socket
          // so we need to convert it
          const inc = [d.data];

          if (current) {
            currentInput.value.data = [...current, ...inc];
          } else {
            currentInput.value.data = inc;
          }
        }
      } else if (
        action.payload.name &&
        action.payload.flowId &&
        action.payload.verificationId
      ) {
        inputsArray.push({
          name: action.payload.name,
          flowId: action.payload.flowId,
          verificationId: action.payload.verificationId,
          value: {
            errors: d.errors ? d.errors : [],
            status: d.status,
            data: [d.data],
            file: d.file ? d.file : null,
            fileName: d.fileName ? d.fileName : null,
          },
        });
      }

      return { ...state };
    }

    case UPDATE_STEPPER_LOADER: {
      const util = state.utils;

      util.map((item) => {
        item.count = action.payload.count;
        return item;
      });

      return { ...state };
    }

    case UPDATE_INPUT_STATUS: {
      const formSection = state.currentSection.name;
      let inputsArray = [...state[formSection]];

      inputsArray = inputsArray.map((input) => {
        if (input.name === action.payload.name) {
          input.validated = action.payload.validated;
        }
        return input;
      });

      return {
        ...state,
        [formSection]: inputsArray,
      };
    }

    case CHANGE_TYPE_OF_PERSON: {
      const valueSelected = action.payload.value;
      let nameOfType;
      if (valueSelected === 1) {
        nameOfType = "moral";
      } else {
        nameOfType = "physical";
      }

      return {
        ...state,
        officialData: [],
        companyData: [],
        commercialInfo: [],
        typeOfPerson: { name: nameOfType, id: valueSelected },
      };
    }

    case UPDATE_RADIO: {
      let radioAlreadyExists;

      const formSection = state.currentSection.name;
      let inputsArray = [...state[formSection]];

      state[formSection].map((input) => {
        if (input.name === action.payload.name) {
          radioAlreadyExists = true;
        }
        return radioAlreadyExists;
      });

      if (radioAlreadyExists) {
        inputsArray = inputsArray.map((input) => {
          if (input.name === action.payload.name) {
            input.value = action.payload.value;
            input.validated = action.payload.validated;
          }
          return input;
        });
      } else {
        inputsArray.push(action.payload);
      }

      return {
        ...state,
        [formSection]: inputsArray,
      };
    }

    case PREV_SECTION: {
      if (state.currentSection.value > 0) {
        const actualValue = state.currentSection.value;
        const newValue = actualValue - 1;

        const sectionNames = [
          "welcome",
          "documents",
          "contact",
          "officialData",
          "companyData",
          "commercialInfo",
          "confirmation",
        ];

        const newSection = { value: newValue, name: sectionNames[newValue] };
        window.scrollTo(0, "smooth");

        return {
          ...state,
          currentSection: newSection,
        };
      }
      return {
        ...state,
      };
    }

    case NEXT_SECTION: {
      if (state.currentSection.value < 6) {
        const actualValue = state.currentSection.value;
        const newValue = actualValue + 1;

        const sectionNames = [
          "welcome",
          "documents",
          "contact",
          "officialData",
          "companyData",
          "commercialInfo",
          "confirmation",
        ];

        const newSection = { value: newValue, name: sectionNames[newValue] };
        window.scrollTo(0, "smooth");

        return {
          ...state,
          currentSection: newSection,
        };
      }
      return {
        ...state,
      };
    }

    case LOG_OUT: {
      localStorage.removeItem("token");
      localStorage.removeItem("email");
      window.scrollTo(0, "smooth");
      return {
        ...state,
        currentSection: { value: 0, name: "welcome" },
      };
    }

    case CLEAR_POP_ADDRESS: {
      const currentSection = state.currentSection.name;
      const currentSectionStr = capitalizeFirstLetter(currentSection);
      const inputsArray = [...state.currentSection];

      const inputsToDelete = [
        `street${currentSectionStr}`,
        `exteriorNumber${currentSectionStr}`,
        `postalCode${currentSectionStr}`,
        `suburb${currentSectionStr}`,
        `municipality${currentSectionStr}`,
        `state${currentSectionStr}`,
        `country${currentSectionStr}`,
        `phoneNumber${currentSectionStr}`,
      ];

      inputsArray.map((input, index) => {
        inputsToDelete.forEach((caseName) => {
          if (input.name === caseName) {
            inputsArray.splice(index, 1);
          }
        });
        return input;
      });

      return {
        ...state,
      };
    }

    // one error
    case ADD_ERROR_MESSAGE_TO_DOCUMENTS: {
      const inputsArray = state.documents;

      const currentInput = inputsArray.find(
        (input) => input.name === action.payload.name
      );

      if (currentInput) {
        let currentErrors = currentInput.value.errors;
        // when file is provided, we remove null file error
        if (currentInput.value.file) {
          currentErrors = currentErrors.filter(
            (error) => error !== "El archivo es requerido"
          );
        }

        if (currentErrors) {
          const newErrors = [...currentErrors, ...action.payload.value.errors];
          currentInput.value.errors = newErrors;
        } else {
          currentInput.value.errors = action.payload.value.errors;
        }
        if (action.payload.value.status) {
          currentInput.value.status = action.payload.value.status;
        }
        const d = action.payload.value;
        if (d.errors) {
          currentInput.value.errors = [...currentErrors, ...d.errors];

          // if action.payload.flowId is ineFron or ineBack
          // update both ineFront and ineBack with the same errors

          if (action.payload.name === "ineBack") {
            const ineFrontInput = inputsArray.find(
              (input) => input.name === "ineFront"
            );
            const ineBackInput = inputsArray.find(
              (input) => input.name === "ineBack"
            );

            if (ineFrontInput) {
              ineFrontInput.value.errors = [...currentErrors, ...d.errors];
            }
            if (ineBackInput) {
              ineBackInput.value.errors = [...currentErrors, ...d.errors];
            }
          }
        }

        if (d.status) {
          currentInput.value.status = d.status;

          if (action.payload.name === "ineBack") {
            const ineFrontInput = inputsArray.find(
              (input) => input.name === "ineFront"
            );
            const ineBackInput = inputsArray.find(
              (input) => input.name === "ineBack"
            );

            if (ineFrontInput) {
              ineFrontInput.value.status = d.status;
            }
            if (ineBackInput) {
              ineBackInput.value.status = d.status;
            }
          }
        }
      } else {
        inputsArray.push(action.payload);
      }
      // TODO: CHANGE THIS TO A BETTER WAY

      return {
        ...state,
      };
    }

    // many errors
    case UPDATE_VALIDATION_ERRORS: {
      const inputsArray = [...state.documents];
      const currentInput = inputsArray.find(
        (input) => input.name === action.payload.name
      );

      if (currentInput !== undefined) {
        const errors = [...currentInput.value.errors, ...action.payload.errors];
        currentInput.value.errors = errors;
      }
      return {
        state,
      };
    }

    case DELETE_ITEM_BY_NAME: {
      const inputsArray = [...state.documents];
      const currentInput = inputsArray.find(
        (input) => input.name === action.payload.name
      );

      if (currentInput) {
        const index = inputsArray.indexOf(currentInput);
        inputsArray.splice(index, 1);
      }
      return {
        ...state,
        documents: inputsArray,
      };
    }

    case UPDATE_METAMAP_FILEUPLOAD_DATA: {
      const index = state.infoMetamap.findIndex(
        (info) => info.name === action.payload.name
      );
      let newArray = null;
      if (index !== -1) {
        newArray = [...state.infoMetamap]; // making a new array
        newArray[index] = action.payload; // changing value in the new array
        return {
          ...state, // copying the orignal state
          infoMetamap: newArray, // reassingning todos to new array
        };
      }
      return {
        ...state,
        infoMetamap: [...state.infoMetamap, action.payload],
      };
    }

    case UPDATE_INPUT_LOGO: {
      const inputsArray = state.documents;

      const currentInput = inputsArray.find(
        (input) => input.name === action.payload.name
      );

      if (currentInput) {
        currentInput.value.url = action.payload.value.url;
      }

      return {
        ...state,
      };
    }

    case COPY_DATA: {
      const keys = Object.keys(action.payload);
      // eslint-disable-next-line no-restricted-syntax
      for (const key of keys) {
        const stateValue = state.contact.find(
          (item) => item.name === action.payload[key]
        );
        if (stateValue === undefined) {
          // eslint-disable-next-line no-continue
          continue;
        }
        const formSection = state.currentSection.name;
        let inputAlreadyExists = false;
        let index = 0;
        state[formSection].forEach((input) => {
          if (input.name === key) {
            inputAlreadyExists = true;
            state[formSection][index].value = stateValue.value;
            state[formSection][index].validated = stateValue.validated;
          }
          index += 1;
        });
        // eslint-disable-next-line no-continue
        if (inputAlreadyExists) continue;
        state[formSection].push({
          name: key,
          value: stateValue.value,
          validated: true,
        });
      }
      return {
        ...state,
      };
    }
    case CLEAN_STORE: {
      const targetSection = action.payload.sectionName;
      const targetFields = action.payload.fields;
      state[targetSection].forEach((input) => {
        if (targetFields.includes(input.name)) {
          input.value = "";
          input.validated = false;
        }
      });

      return { ...state };
    }
    case LIST_FILE_ERROR: {
      const util = state.utils;

      util.map((item) => {
        item.fileError = action.payload.fileError;
        return item;
      });

      return { ...state };
    }
    default:
      return state;
  }
};
