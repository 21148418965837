function getToken() {
  return localStorage.getItem("token");
}
function setLastSaveTime() {
  localStorage.setItem("lastSaveTime", Date.now());
}
export default function saveState(state) {
  return fetch(process.env.REACT_APP_UPDATE_LEAD, {
    method: "POST",
    body: JSON.stringify(state),
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    keepalive: true,
  })
    .then((response) => response.json())
    .then(() => {
      console.log("Datos guardados correctamente");
      setLastSaveTime();
    });
}
