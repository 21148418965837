import { toUppercase } from "../../utils/sass/js/toUppercase";

export function Associate(
  officialData,
  companyData,
  commercialInfo,
  contact,
  phoneAsociado,
  typeOfCompany,
  logo
) {
  const {
    stateCompanyData,
    cityCompanyData,
    municipalityCompanyData,
    suburbCompanyData,
    streetCompanyData,
    interiorNumberCompanyData,
    exteriorNumberCompanyData,
    postalCodeCompanyData,
    companyDataFacebook,
    companyDataInstagram,
    companyDataWebsite,
    companyDataTwitter,
    companyDataKeywords,
    companyDataBankName,
    companyDataCompanyName,
    companyDataBankCLABE,
    companyDataBankNumber,
    companyDataCommercialName,
  } = companyData;
  // RFC should be uppercase
  let { companyDataRFC } = companyData;
  let physicalTaxpayerRfc;

  companyDataRFC = toUppercase(companyDataRFC);

  if (companyDataRFC === undefined) {
    physicalTaxpayerRfc = officialData.physicalTaxpayerRfc;
    physicalTaxpayerRfc = toUppercase(physicalTaxpayerRfc);
  }

  const {
    commercialInformationMainCategorySales,
    commercialInformationNumberOfSub,
    commercialInformationBillsRange,
  } = commercialInfo;

  const {
    stateOfficialData,
    suburbOfficialData,
    cityOfficialData,
    municipalityOfficialData,
    postalCodeOfficialData,
    exteriorNumberOfficialData,
    streetOfficialData,
    physicalTaxpayerName,
    physicalTaxpayerLastname,
    physicalTaxpayerMothersLastname,
  } = officialData;
  // eslint-disable-next-line sonarjs/prefer-immediate-return
  const debugg = {
    business_number: null,
    active: 3, // 3 = Pending wallets
    user_last_modified: 1,
    // date_last_modified: null,
    rfc: companyDataRFC || physicalTaxpayerRfc,
    entity_id: stateCompanyData || stateOfficialData,
    city_id:
      (cityCompanyData !== "" ? cityCompanyData : municipalityCompanyData) ||
      (cityOfficialData !== "" ? cityOfficialData : municipalityOfficialData),
    municipality_id: municipalityCompanyData || municipalityOfficialData,
    street: streetCompanyData || streetOfficialData,
    interior_number: interiorNumberCompanyData || "",
    outdoor_number: exteriorNumberCompanyData || exteriorNumberOfficialData,
    colony: suburbCompanyData || suburbOfficialData,
    postal_code: postalCodeCompanyData || postalCodeOfficialData,
    facebook: companyDataFacebook ?? null,
    instagram: companyDataInstagram ?? null,
    website: companyDataWebsite ?? null,
    twitter: companyDataTwitter ?? null,
    bank_name: companyDataBankName,
    authorization_methods: null, // can go null
    phone: phoneAsociado,
    product_id: 1,
    country_id: 1,
    // key words
    keyword: companyDataKeywords,
    paydays: "1|8|16|24",
    category_id: commercialInformationMainCategorySales,
    company_id: null,
    associated_name:
      companyDataCompanyName ||
      `${physicalTaxpayerName} ${physicalTaxpayerLastname} ${physicalTaxpayerMothersLastname}`,
    bank_interbank_key: companyDataBankCLABE,
    logo: logo || "", // can go null
    email: contact.contactAdminEmail,
    business_name: companyDataCommercialName,
    branches_number: commercialInformationNumberOfSub || "0",
    monthly_billing: commercialInformationBillsRange,
    tax_regime: typeOfCompany === 612 ? officialData.physicalTaxpayerRegimen : officialData.moralTaxpayerRegimen,
    // num subsuenta
    subaccount_number: null,
    description_cfdi: "G03",
    bank_account_number: companyDataBankNumber,
    bank_account_holder: contact.contactAdminName.trim(),
  };
  return debugg;
}
